import { useState, useEffect, FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GetActiveAplications } from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
import { storage } from '@/core/utils';
import imge1 from '@/assets/img2.jpg';
import imge2 from '@/assets/aims_logo_2.png';
import IconArrowLeft from '@/components/Icon/IconArrowLeft';
import IconShare from '@/components/Icon/IconShare';
import IconSearch from '@/components/Icon/IconSearch';
import IconLogin from '@/components/Icon/IconLogin';
import { CircularProgress } from '@mui/material';

const Home: FunctionComponent = () => {
    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[38px]  gap-[22px] tracking-[normal] text-left text-5xl text-gray-200 font-outfit">
            <section className="self-stretch flex flex-col items-start justify-start max-w-full">
                <div className="self-stretch bg-maroon flex flex-col items-start justify-start pt-0 px-0 pb-[205px]  relative gap-[99px] max-w-full mq750:gap-[49px_99px] mq750:pb-[133px] mq750:box-border mq450:gap-[25px_99px]">
                    <img
                        className="w-full h-[1104px] absolute !m-[0]  left-0 top-[-309px] object-cover"
                        alt=""
                        src={imge1}
                        style={{ filter: 'brightness(50%)' }}
                    />
                    <FrameComponent2 />
                    <MASTERPROGRAMSF />
                </div>

                <ForgotPasswordLink />
            </section>
            <FrameComponent />
            <div className="w-[608px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                <div className="relative text-lg ">
                    © 2024.AIMS RIC All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default Home;
const FrameComponent2: FunctionComponent = () => {
    return (
        <header className="self-stretch h-[75px] box-border overflow-hidden shrink-0 flex flex-row items-start justify-between pt-[13px] pb-0 pr-[120px] pl-[85px] top-[0] z-[99] sticky max-w-full gap-[20px] border-b-[2px] border-solid border-darkgray mq750:pl-[42px] mq750:pr-[60px] mq750:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
            <img
                className="mt-[-14px] h-[76px] w-[326px] relative object-cover max-w-full"
                alt=""
                src={imge2}
            />
            <nav className="m-0 w-[382px] flex flex-row items-start justify-start gap-[34px] max-w-full text-center text-lg text-white font-outfit mq750:hidden mq450:gap-[34px_17px]">
                <div className="w-[106px] flex flex-col items-start justify-start pt-[12.5px] px-0 pb-0 box-border">
                    <div className="self-stretch relative capitalize font-medium inline-block min-w-[106px] whitespace-nowrap">
                        how it works
                    </div>
                </div>
                <div className="w-[67px] flex flex-col items-start justify-start pt-[12.5px] px-0 pb-0 box-border">
                    <Link to="/register" className="cursor-pointer">
                        <div className="self-stretch relative capitalize font-medium inline-block min-w-[67px]">
                            register
                        </div>
                    </Link>
                </div>
                <Link to="/login">
                    <button className="cursor-pointer  flex flex-row justify-center  [border:none] py-3 px-8 bg-primary h-12 flex-1 rounded-lg overflow-hidden items-center  box-border gap-[4px] whitespace-nowrap hover:bg-primary">
                        <div className="flex-1 relative text-lg capitalize font-medium font-outfit text-white text-center inline-block min-w-[49px]">
                            log in
                        </div>

                        <IconArrowLeft className="w-6 h-6  text-white " />
                    </button>
                </Link>
            </nav>
        </header>
    );
};

const MASTERPROGRAMSF: FunctionComponent = () => {
    return (
        <div className="w-[1428px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-13xl text-primary font-outfit">
            <div className="w-[938px] flex flex-col items-center justify-start gap-[16px] max-w-full z-[1]">
                <div className="self-stretch flex flex-col items-center justify-start gap-[12px] max-w-full">
                    <h2 className="m-0 relative text-inherit leading-[40px] capitalize font-bold font-inherit inline-block mq450:text-lgi mq450:leading-[24px] mq1050:text-7xl mq1050:leading-[32px]">
                        welcome to aims
                    </h2>
                    <div className="self-stretch flex flex-col items-center justify-start gap-[4px] max-w-full text-center text-21xl text-white">
                        <h1 className="m-0 self-stretch relative text-inherit leading-[56px] capitalize font-black font-inherit mq450:text-5xl mq450:leading-[34px] mq1050:text-13xl mq1050:leading-[45px]">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
                        <div className="w-[677px] relative text-xl leading-[32px] capitalize font-medium inline-block max-w-full mq450:text-base mq450:leading-[26px]">
                            Join us on a journey of discovery and innovation.
                        </div>
                    </div>
                </div>
                <Link to="/register" className="cursor-pointer">
                    <button className="cursor-pointer [border:none] py-4 px-[63px] bg-primary w-[255px] h-14 rounded-2xl overflow-hidden shrink-0 flex flex-row items-center justify-center box-border gap-[10px] whitespace-nowrap hover:bg-primary mq450:pl-5 mq450:pr-5 mq450:box-border">
                        <div className="flex-1 relative text-lg capitalize font-medium font-outfit text-white text-center inline-block min-w-[95px]">
                            get started
                        </div>
                        <IconArrowLeft className="w-6 h-5   text-white" />
                    </button>
                </Link>
            </div>
        </div>
    );
};

export type FrameComponent1Type = {
    fgdshaklLSDJKKSLAKSJDFVHD?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = ({
    fgdshaklLSDJKKSLAKSJDFVHD,
}) => {
    const navigate = useNavigate();
    const [expandedApplications, setExpandedApplications] = useState<string[]>(
        []
    );
    const [showInstructionNote, setShowInstructionNote] =
        useState<boolean>(false);
    const query = location.search;
    const { loadingApplications, handleGetApplications, applications } =
        GetActiveAplications();

    useEffect(() => {
        handleGetApplications(query);
    }, [query]);

    const token = storage.getToken();
    const [expandedApplicationsDetails, setExpandedApplicationsDetails] =
        useState<string[]>([]);

    const redirectToApplications = () => {
        if (token) {
            navigate('/applicant/application');
        } else {
            navigate('/login');
        }
    };

    const toggleApplicationDetails = (applicationId: string) => {
        setExpandedApplications(prevExpanded => {
            if (prevExpanded.includes(applicationId)) {
                return prevExpanded.filter(id => id !== applicationId);
            } else {
                return [...prevExpanded, applicationId];
            }
        });
    };

    const isExpanded = (applicationId: string) => {
        return expandedApplications.includes(applicationId);
    };

    const renderViewButton = (applicationId: string) => {
        return (
            // <button
            //     className="cursor-pointer border-none py-3 px-10 bg-primary w-[200px] h-12 rounded-xl overflow-hidden shrink-0 flex flex-row items-center justify-center box-border gap-[10px] whitespace-nowrap hover:bg-primary"
            //     onClick={() => toggleApplicationDetails(applicationId)}
            // >
            //     <div className="flex-1 relative text-lg capitalize font-outfit text-white text-center inline-block min-w-[86px]">
            //         {isExpanded(applicationId) ? 'View Less' : 'View More'}
            //     </div>
            //     <IconArrowLeft className="w-5 h-5 text-white" />
            // </button>
            <Link
                className="cursor-pointer border-none py-3 px-10 bg-primary text-white  mt-4  w-[200px] h-12 rounded-xl overflow-hidden shrink-0 flex flex-row items-center justify-center box-border gap-[10px] whitespace-nowrap hover:bg-primary"
                to='/register' target="_blank"
            >
                Apply Now
                <IconArrowLeft className="w-5 h-5 text-white" />
            </Link>
        );
    };

    return (
        <div className="flex-1 bg-gray-100 overflow-hidden flex flex-col items-start justify-start p-6 box-border min-w-[367px] max-w-full text-center text-xl text-black font-font-outfit mq750:min-w-full">
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
                {applications &&
                    applications?.list.map(
                        (application: ApplicationType, index: number) => (
                            <div key={index} className="">
                                <div className="flex flex-col gap-3 justify-start">
                                    <b className="text-start w-[316px] relative capitalize inline-block mq450:text-base">
                                        {application.title}
                                    </b>
                                    <div
                                        className="self-stretch relative text-lg capitalize text-dimgray-200 text-left [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                application?.description || '',
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 justify-start ">
                                    {/* <b className="text-start mt-4 text-lg font-bold">
                                        Instructions
                                    </b> */}
                                    {isExpanded(application.id) && (
                                        <div
                                            className="self-stretch relative text-lg capitalize text-dimgray-200 text-left [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    application.instruction_note ||
                                                    '',
                                            }}
                                        />
                                    )}
                                    {application.instruction_note.length >
                                        100 && renderViewButton(application.id)}
                                </div>
                            </div>
                        )
                    )}
                {loadingApplications && (
                    <div className="flex justify-center items-center text-lg h-20">
                        <CircularProgress />
                    </div>
                )}
            </div>
        </div>
    );
};
const ForgotPasswordLink: FunctionComponent = () => {
    return (
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full mt-[-121px] text-left text-13xl text-primary font-outfit">
            <div className="w-[1268px] rounded-13xl bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[45px] px-[58px] pb-[87px] box-border gap-[53px] max-w-full z-[2] lg:pl-[29px] lg:pr-[29px] lg:box-border mq750:gap-[26px_53px] mq750:pt-[141px] mq750:pb-[57px] mq750:box-border">
                <div className="self-stretch flex flex-row items-start justify-between gap-[20px] max-w-full mq750:flex-wrap">
                    <h2 className="m-0 h-20 relative text-inherit capitalize font-bold font-inherit inline-block max-w-full mq450:text-lgi mq1050:text-7xl">
                        <p className="m-0">explore</p>
                        <p className="mt-2 ">available applications</p>
                    </h2>
                    <div className="w-[188px] flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border">
                        <Link to="/login" className="cursor-pointer">
                            <button className="cursor-pointer [border:none] py-4 px-12 bg-seashell self-stretch h-14 rounded-xl flex flex-row items-start justify-start box-border gap-[4px] whitespace-nowrap hover:bg-gainsboro">
                                <div className="flex-1 relative text-lg capitalize font-medium font-outfit text-primary text-center inline-block min-w-[64px]">
                                    view all
                                </div>
                                <IconArrowLeft className="w-6 h-5 " />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px] max-w-full text-center text-xl text-black">
                    <FrameComponent1 />
                </div>
            </div>
        </div>
    );
};

const FrameComponent: FunctionComponent = () => {
    return (
        <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[23px] box-border max-w-full text-center text-base text-primary font-outfit">
            <div className="flex-1 bg-whitesmoke overflow-hidden flex flex-row items-start justify-center py-[63px] pr-5 pl-[34px] box-border max-w-full mq450:pt-[41px] mq450:pb-[41px] mq450:box-border">
                <div className="w-[1146px] rounded-sm flex flex-row items-center justify-start gap-[40px] max-w-full mq750:gap-[40px_20px] mq1050:flex-wrap">
                    <div className="w-[462px] flex flex-col items-start justify-start py-5 px-0 box-border gap-[16px] min-w-[462px] max-w-full mq750:min-w-full mq1050:flex-1">
                        <div className="w-[211px] h-[29.8px] flex flex-col items-start justify-start gap-[8px]">
                            <img
                                className="w-[30.6px] h-[1.8px] relative object-contain"
                                loading="lazy"
                                alt=""
                                src="/vector.svg"
                            />
                            <div className="self-stretch relative font-medium whitespace-pre-wrap">
                                HOW APPLICATION WORKS
                            </div>
                        </div>
                        <b className="self-stretch relative text-5xl capitalize text-gray-300 text-left mq450:text-lgi">
                            <p className="m-0">
                                Your Guide to Understanding the Inner Workings!"
                            </p>
                        </b>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-start gap-[8px] min-w-[419px] max-w-full text-left text-xl text-black mq750:min-w-full">
                        <div className="self-stretch flex flex-row items-start justify-center gap-[8px] max-w-full mq750:flex-wrap">
                            <div className="flex-1 rounded-lg bg-white flex flex-row items-center justify-start py-6 px-8 box-border gap-[24px] min-w-[212px] max-w-full mq450:flex-wrap">
                                <div className=" flex flex-col items-start justify-center">
                                    <IconLogin className="w-12 h-12 text-primary" />
                                </div>
                                <Link to="/register">
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[135px]">
                                        <b className="relative capitalize inline-block min-w-[81px] mq450:text-base">
                                            Sign-Up:
                                        </b>
                                        <div className="self-stretch h-11 relative text-base text-dimgray-100 whitespace-pre-wrap inline-block shrink-0">{`Fill in Your Details  To  have A n Account `}</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="h-[125px] w-[310px] rounded-lg bg-white flex flex-row items-center justify-start py-9 px-6 box-border gap-[24px] min-w-[310px] mq750:flex-1">
                                <IconLogin className="w-12 h-12 text-primary" />
                                <Link to="/login">
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                        <b className="relative capitalize inline-block min-w-[62px] mq450:text-base">
                                            Log in:
                                        </b>
                                        <div className="self-stretch relative text-base text-dimgray-100">
                                            Fill in your credentials
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-center gap-[8px] max-w-full mq750:flex-wrap">
                            <div className="flex-1 rounded-lg bg-white flex flex-row items-center justify-start py-6 px-8 box-border min-w-[212px] max-w-full">
                                <div className="flex-1 flex flex-row items-center justify-start gap-[24px] mq450:flex-wrap">
                                    <IconSearch className="w-12 h-12  text-primary" />
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[135px]">
                                        <b className="relative capitalize inline-block min-w-[71px] mq450:text-base">
                                            Explore
                                        </b>
                                        <div className="self-stretch relative text-base text-dimgray-100">
                                            Explore the available Applications
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/register">
                                <div className="h-[125px] w-[310px] rounded-2xl bg-white flex flex-row items-center justify-start py-9 px-6 box-border gap-[24px] min-w-[310px] mq750:flex-1">
                                    <IconShare className="w-12 h-12 text-primary " />
                                    <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                        <b className="relative capitalize inline-block min-w-[100px] mq450:text-base">
                                            apply now
                                        </b>
                                        <div className="self-stretch relative text-base text-dimgray-100">{`Fill th e form `}</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
