import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { z } from 'zod';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';

import {
    GetSubmittedApplications,
    SubmitApplication,
} from '@/core/hooks/application';
import {
    ApplicationFilterPayload,
    ApplicationType,
} from '@/core/types/application';

import { UserFilterPayload } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import { useGetAllUsers } from '@/core/hooks/auth';
import IconEye from '@/components/Icon/IconEye';

export default function SubmittedApplications() {
    const { applicationId }: any = useParams();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const { loadingSubmit, submitApplication } = SubmitApplication();
    const handleApply = (application_id: any) => {
        submitApplication(application_id);
    };

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        GetSubmittedApplications();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const updatePageSizeInQuery = (
        query: string,
        applicationId: string
    ): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('applicationId', applicationId.toString());
        return '?' + urlParams.toString();
    };

    const PageSize = 1000000;
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, applicationId);
        handleGetApplications(updatedQuery);
    }, [query]);
    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application',
            accessor: 'application.title',
            render: row => (
                <p className="capitalize">{row?.application?.title}</p>
            ),
        },
        {
            title: 'Description',
            accessor: 'application.description',
            render: row => (
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (row?.application.description || '').slice(
                                0,
                                100
                            ),
                        }}
                    />
                </div>
            ),
        },

        {
            title: 'Application deadline',
            accessor: 'application.ending_date',
            render: row => (
                <p>{formatDateToLongForm(row?.application?.ending_date)}</p>
            ),
        },
        {
            title: 'User Name',
            accessor: 'user.firstName',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.user?.firstName} {row?.user?.lastName}
                </p>
            ),
        },
        {
            title: 'User Email',
            accessor: 'user.email',
            render: row => (
                <p className=" whitespace-wrap">{row?.user?.email}</p>
            ),
        },

{
            title: 'Nationality',
            accessor: 'user.personalInformation.Nationality',
            render: (row) => (
              <p className="capitalize whitespace-wrap">
                {row?.user?.personalInformation[0]?.Nationality ?? '-'}
              </p>
            ),
        },
          

        {
            title: 'Date Submitted  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.submitted_date)}</p>,
        },
        {
            title: 'Evaluation status',
            accessor: 'row.status',
            render: row => {
                let badgeColor;
                switch (row?.evaluationStatus) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'rejected':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'primary';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        badgeColor = 'primary';
                }
                return (
                    <span className={` badge bg-${badgeColor}`}>
                        {row.evaluationStatus}
                    </span>
                );
            },
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <>
                        <button
                            onClick={() => {
                                navigate(
                                    `/account/admin/user_details/submitted/${row?.user?.id}/${row.application.id}`
                                );
                            }}
                            className="btn btn-primary btn-sm "
                        >
                            <IconEye />
                        </button>
                    </>

                    <></>
                </div>
            ),
        },
    ];

    return (
        <>
            <div>
                <div className="my-4 ">
                    <p className="text-lg font-bold ">
                        Submitted Applications{' '}
                    </p>
                </div>
            </div>
            <div className="panel py-4 ">
                <div className="">
                    {user.role === 'admin' && (
                        <DataTableV2
                            tableName="Submitted Applications"
                            columns={columns}
                            previousPage={applications?.previousPage ?? 0}
                            nextPage={applications?.nextPage ?? 0}
                            currentPage={applications?.currentPage ?? 0}
                            data={applications?.list ?? []}
                            total={applications?.total ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                            header={
                                <div className="w-full flex my-3 justify-end items-center">
                                    <Filter
                                        filters={filters}
                                        data={applications}
                                        onFilter={(
                                            payload: ApplicationFilterPayload
                                        ) => {
                                            setFilters(payload);
                                        }}
                                    />
                                </div>
                            }
                        />
                    )}

                    {/* <DeletApplications
        refresh={setRefresh}
        selected={selected}
        open={open}
        setOpen={setOpen}
        title={'Delete Applications'}
    /> */}
                </div>
            </div>
        </>
    );
}

type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        userId: z.string().optional(),
    });

    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, userId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (userId) searchParams.set('userId', userId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const query = location.search;
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    useEffect(() => {
        handleGetUsers(query);
    }, []);

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<ApplicationFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-1">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                <SelectField
                                    placeholder="Select User"
                                    isLoading={loadingUsers}
                                    label="User"
                                    error={formState.errors.userId}
                                    options={(users?.list || [])
                                        .filter(
                                            (user: {
                                                status: string;
                                                role: string;
                                            }) =>
                                                user.status === 'active' &&
                                                user.role === 'admin'
                                        )
                                        .map(
                                            (user: {
                                                id: any;
                                                firstName: any;
                                                lastName: any;
                                            }) => ({
                                                value: user?.id,
                                                text: `${user?.firstName} ${user?.lastName}`,
                                            })
                                        )}
                                    registration={register('userId')}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
