import {
    Confirm_reset,
    activate_user,
    admin_create_user,
    delete_user,
    edit_user,
    get_applicants,
    get_users,
    login_user,
    register_user,
    request_reset,
    user_profile,
} from '@/core/api/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useAppDispatch } from '@/store';
import { addUser, removeUser } from '@/store/slices/userslice';
import { storage } from '@/core/utils';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AddAcademicInfo } from '@/app/dashboard/academics/modal/add_academic';
import { RegisterInput } from '@/app/dashboard/users/modals/createUser';
import { set } from 'lodash';
import axios from 'axios';
import config from '@/config';

export const useGetAllUsers = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers]: any = useState<PaginationType<UserType> | null>();
    const handleGetUsers = async (query?: string, search?: string) => {
        try {
            setLoadingUsers(true);
            const data: PaginationType<UserType> = await get_users(query);
            setUsers(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingUsers(false);
        }
    };

    return {
        loadingUsers,
        users,
        handleGetUsers,
    };
};

export const useGetAllApplicants = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [applicants, setApplicant]: any =
        useState<PaginationType<UserType> | null>();
    const handleApplicants = async (query?: string, search?: string) => {
        try {
            setLoadingUsers(true);
            const data: PaginationType<UserType> = await get_applicants(query);
            setApplicant(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingUsers(false);
        }
    };

    return {
        loadingUsers,
        applicants,
        handleApplicants,
    };
};
export const getMyProfile = () => {
    const [loadingPrifile, setLoadingProfile] = useState(false);
    const [profile, setProfile]: any =
        useState<PaginationType<UserType> | null>();
    const handleGetProfile = async (query?: string, search?: string) => {
        try {
            setLoadingProfile(true);
            const data: PaginationType<UserType> = await user_profile();
            setProfile(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingProfile(false);
        }
    };

    return {
        loadingPrifile,
        profile,
        handleGetProfile,
    };
};
export const EditUser = () => {
    const [loadingEditUser, setLoadingEditEvent] = useState(false);
    const hanldeEditUser = async (payload: UserPayload, id: string) => {
        try {
            setLoadingEditEvent(true);
            edit_user(payload, id).then(() => toast.success('User updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditEvent(false);
        }
    };

    return {
        hanldeEditUser,
        loadingEditUser,
    };
};export const useLoginUser = () => {
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const handleLogin = async (payload: AuthLoginPayload) => {
        try {
            setLoadingLogin(true);
            setError(null);
            const response:any = await axios.post(`${config.API_URL}/api/v1/user/login`, payload)
            console.log(response)
            const { access_token, user } = response.data;

            // Set token in storage and update user state
            storage.setToken(access_token);
            dispatch(addUser(user));
            
            // Redirect to account page based on user role
            window.location.href = `/account/${user?.role}`;
        } catch (error: any) {
            console.log(error)
           
            if (error?.response?.status === 401) {
                toast.error("Invalid username or password");
            } else {
                toast.error(error.response.message || "Something went wrong");
            }
        } finally {
            setLoadingLogin(false);
        }
    };

    return {
        loadingLogin,
        handleLogin,
        error,
    };
};

export const useCreateUser = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const handleCreate = async (payload: UserPayload) => {
        try {
            setLoadingCreate(true);
            register_user(payload).then(() => toast.success('User Created'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const AdminCreateUser = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const navigate = useNavigate();
    const [ isSucceess, setIssuccesss] = useState(false)
    const handleCreate = async (payload: RegisterInput) => {
        try {
            setLoadingCreate(true);
            const { res } = await admin_create_user(payload);
            setIssuccesss(true)
        toast.success('User Created');
        } catch (error: any) {
            setIssuccesss(false)
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
        isSucceess
    };
};

export const SignUp = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleCreate = async (payload: UserPayload) => {
        try {
            setLoadingCreate(true);
            const response = await register_user(payload);
            const { access_token, user } = await response;
            storage.setToken(access_token);
            console.log(user);
            dispatch(addUser(user));

            navigate('/user/activate/account');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const useDeleteUser = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const handleDelete = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_user(id);
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDelete,
    };
};

export const useActivateUser = () => {
    const [loadingActivate, setLoadingActivate] = useState(false);
    const navigate = useNavigate();
    const handleActivate = async (activation_code: string) => {
        try {
            setLoadingActivate(true);
            await activate_user(activation_code);
            toast.success('Your Account Has been Activated');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingActivate(false);
        }
    };

    return {
        loadingActivate,
        handleActivate,
    };
};

export const RequestOtp = () => {
    const [loadingReset, setLoadingReset] = useState(false);
    const navigate = useNavigate();
    const handleReset = async (payload: UserPayload) => {
        try {
            setLoadingReset(true);
            await request_reset(payload);

            navigate('/confirm_reset');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingReset(false);
        }
    };

    return {
        loadingReset,
        handleReset,
    };
};

export const ResetPassword = () => {
    const [loadingReset, setLoadingReset] = useState(false);
    const navigate = useNavigate();
    const handleReset = async (payload: UserPayload) => {
        try {
            setLoadingReset(true);
            await Confirm_reset(payload);
            toast.success('Password Reset ');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingReset(false);
        }
    };

    return {
        loadingReset,
        handleReset,
    };
};

export const useLogout = () => {
    const [loadingLogout, setLoadingLogout] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        try {
            setLoadingLogout(true);
            storage.removeToken();
            dispatch(removeUser());
            window.location.href = '/login';
        } catch (error) {
            toast.error('Logout Failed');
        } finally {
            setLoadingLogout(false);
        }
    };

    return {
        loadingLogout,
        handleLogout,
    };
};
